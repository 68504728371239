<template>
    <!-- drawer component -->
    <div
        ref="menuDiv"
        id="right-navigation"
        class="fixed right-0 top-[-56px] z-[1001] block h-screen min-h-screen w-full translate-x-full overflow-hidden bg-transparent transition-transform dark:bg-transparent sm:top-[-85px]"
        tabindex="-1"
        aria-labelledby="drawer-right-label"
        aria-hidden="false">
        <div
            class="close-menu-area absolute h-[200%] w-[21%] sm:w-[70%]"
            data-drawer-hide="right-navigation"></div>

        <div
            class="menuDiv absolute right-0 flex h-[100vh] min-h-screen w-[80%] flex-col bg-black sm:w-[476px]">
            <div
                id="insideMenu"
                class="insideMenu absolute right-0 top-0 z-[100] flex min-h-screen w-[100%] flex-col overflow-hidden bg-white px-[18px] pb-[100px] pt-[62px] dark:bg-black sm:w-[476px] sm:px-[81px] sm:pt-[40px]">
                <button
                    type="button"
                    @click="isMoreMenuOpen = false"
                    data-drawer-hide="right-navigation"
                    aria-controls="right-navigation"
                    class="absolute right-[17px] top-[17px] z-[1000] inline-flex h-[25px] w-[25px] items-center justify-center bg-transparent text-gray-400 hover:text-gray-900 dark:hover:text-gray-900 lg:right-[83px] lg:top-[25px] xl:right-[63px] xl:top-[22px]">
                    <font-awesome-icon
                        class="h-[25px] w-[25px] text-iconWhite hover:text-iconhover sm:h-[35px] sm:w-[35px]"
                        :icon="['fas', 'xmark']" />
                </button>
                <div class="flex-grow">
                    <div
                        class="items-left flex flex-col space-y-[40px] px-[18px] pb-[71px] sm:pt-[65px]">
                        <Link
                            :href="`/create`"
                            data-drawer-hide="right-navigation"
                            class="mx-auto flex w-[290px] items-center justify-center space-x-2 rounded-full bg-[#E86599] px-8 py-3 text-white transition-colors duration-200 hover:bg-pink-600"
                            @click="fathomClickGuestCreateBtn">
                            <img
                                src="/images/waveform-icon.png"
                                alt="Waveform"
                                class="max-w-[20px]" />
                            <span class="whitespace-nowrap text-base font-medium"
                                >Create your own Girlfriend</span
                            >
                        </Link>

                        <template v-for="link in navigationLinks">
                            <!-- If link.emit exists, render this link -->
                            <div
                                v-if="link.emit"
                                :key="`${link.label}-emit`"
                                class="link-hover flex cursor-pointer items-center text-[18px] leading-[20px] text-black hover:text-mintgreen dark:text-white"
                                data-drawer-hide="right-navigation"
                                @click="$emit(link.emit)">
                                <font-awesome-icon
                                    class="h-[20px] w-[20px] pr-[14px] text-black dark:text-white"
                                    :icon="['fas', link.icon]" />
                                <span>{{ link.label }}</span>
                            </div>

                            <!-- If link.emit doesn't exist, render this link -->
                            <Link
                                v-else
                                preserve-scroll
                                :key="link.label"
                                class="link-hover flex items-center text-[18px] leading-[20px] text-black hover:text-mintgreen dark:text-white"
                                data-drawer-hide="right-navigation"
                                :href="route(link.route)">
                                <font-awesome-icon
                                    class="h-[20px] w-[20px] pr-[14px] text-black dark:text-white"
                                    :icon="['fas', link.icon]" />
                                <span>{{ link.label }}</span>
                            </Link>
                        </template>

                        <!-- Logged Out-->
                        <div>
                            <Link
                                @click="handleJoinForFree"
                                data-drawer-hide="right-navigation"
                                class="mx-auto block w-full rounded-md border border-mintgreen text-center text-[14px] font-medium leading-[48px] tracking-wide text-black hover:bg-mintgreen dark:text-white dark:hover:text-black sm:text-xl sm:leading-[57px]"
                                :href="route('access', { type: 'join' })">
                                Join For Free or Login
                            </Link>

                            <Link
                                data-drawer-hide="right-navigation"
                                class="mx-auto mb-[30px] mt-[14px] block w-full rounded-md bg-mintgreen text-center text-[14px] font-medium leading-[48px] tracking-wide text-black hover:bg-hovermintgreen sm:mt-5 sm:text-xl sm:leading-[57px]"
                                :href="route('creator.signup')">
                                Become a Creator
                            </Link>
                            <FooterLinks />
                            <div class="hidden w-full pt-5">
                                <theme-toggle />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { Link } from '@inertiajs/vue3'
    import ThemeToggle from '@/Components/ThemeToggle.vue'

    import FooterLinks from '@/Components/navigation/FooterLinks.vue'
    import { onMounted } from 'vue'
    import { initFlowbite } from 'flowbite'

    const emit = defineEmits(['showFlyout', 'resetAndNavigate', 'goMessagePage'])

    const props = defineProps({
        pathNameProp: {
            type: String,
            default: undefined,
        },
    })

    const navigationLinks = [
        { icon: 'stars', label: 'Creators', route: 'creator.index' },
        { icon: 'hashtag', label: 'Categories', route: 'tags.index' },
        { icon: 'comment', label: 'Messages', route: 'message', emit: 'goMessagePage' },
        { icon: 'bookmark', label: 'Saved', route: 'home', emit: 'showFlyout' },
        { icon: 'circle-arrow-up', label: 'Upload Videos', route: 'creator.signup' },
        {
            icon: 'magnifying-glass',
            label: 'Explore',
            emit: 'resetAndNavigate',
        },
    ]

    const handleLinkClick = link => {
        if (link.emit) {
            emit(link.emit)
        }
    }

    const handleJoinForFree = () => {
        if (props.pathNameProp === '/message') {
            fathom.trackEvent('exn_lander_join_nav_click')
        }
    }

    const fathomClickGuestCreateBtn = () => {
        fathom.trackEvent('guest_create_btn')
    }

    onMounted(() => {
        initFlowbite()
    })
</script>
<style>
    .link-hover:hover span,
    .link-hover:hover svg {
        color: #65e8a4;
    }

    .transition-transform {
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 200ms !important;
    }
</style>
